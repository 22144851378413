import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {StudentRb} from "~/models/request/studentRb";
import type {GetStudentsByUserIdEmailRb} from "~/models/request/getStudentsByUserIdEmailRb";
import type {GetClubStatusByStudentIdsRb} from "~/models/request/getClubStatusByStudentIdsRb";
import type {StudentDetails} from "~/models/parentDetails";
import type {LocalParticipant} from "~/models/localParticipant";
import type {Student} from "~/models/student";
import type {AddMultipleParticipantsRb} from "~/models/request/addMultipleParticipantsRb";
import {useGlobalEvent, useNotificationEvent} from "~/composables/useEventBus";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {StudentStatus} from "~/utils/enums/StudentStatus";
import type {StudentStatistics} from "~/models/StudentsStatistics";
import type {GetStudentDataPaginationRb} from "~/models/request/getStudentDataPaginationRb";
import type {DeleteStudentRb} from "~/models/request/DeleteStudentRb";

export const studentStore = defineStore({
    id: 'student-store',
    persist: true,
    state: () => {
        return {
            allStudentOfCurrentCompany: <Student[]>[],
            listOfStudent: <StudentDetails[]>[],
            loggedInStudent: <Student>{},
            studentsRecords: <Student[]>[],
            studentStatistics: <StudentStatistics>{},
            studentTableSortKey: {}
        }
    },
    actions: {
        clearStudentStore: function () {
            this.clearListOfStudent();
            this.clearLoggedInStudent();
        },
        clearLoggedInStudent: function () {
            this.loggedInStudent = <Student>{};
        },
        clearListOfStudent: function () {
            this.listOfStudent = [];
        },
        setListOfStudent: function (students: []) {
            this.listOfStudent = []
            for (const student of students) {
                this.listOfStudent.push(student as StudentDetails)
            }
        },
        setStudentTableSortKey: function(sortObj: { sortCol: string, sortDirection: string}) {
            this.studentTableSortKey = sortObj;
        },
        addStudent: async function (student: StudentRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Student/addstudent';
            const ns = new NetworkService();
            student.Phone = (student.Phone ?? '').length < 1 ? '' : ('1' + student.Phone);
            let {Result} = await ns.post$fetch(url, {'student': student}, null, "addStudent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        deleteStudent: async function (deleteStudent: DeleteStudentRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Student/deletestudent';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, deleteStudent, null, "deleteStudent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        addStudentServer: async function (student: StudentRb) {
            const config = useRuntimeConfig()
            let url = '/api/student/add';
            const ns = new NetworkService();
            let {
                Result,
                ErrorMessages
            } = await ns.post$fetch(url, {'student': student}, null, "addStudentServer" + new Date().getMilliseconds() + "_")
            return {Result, ErrorMessages};
        },
        addStudentServerWithAdditionalInfo: async function (student: any) {
            const config = useRuntimeConfig()
            let url = '/api/student/add';
            const ns = new NetworkService();
            let {Result, ErrorMessages} = await ns.post$fetch(url, {...student}, null, "addStudentServerWithAdditionalInfo" + new Date().getMilliseconds() + "_")
            return {Result, ErrorMessages};
        },
        addMultipleParticipant: async function (addMultipleParticipantsRb: AddMultipleParticipantsRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/addMultipleParticipants';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addMultipleParticipantsRb, null, "addMultipleParticipant" + new Date().getMilliseconds() + "_")
            return Result ?? {};
        },
        addMultipleParticipantServer: async function (addMultipleParticipantsRb: AddMultipleParticipantsRb) {
            const config = useRuntimeConfig()
            let url = '/api/student/add-multiple-participant';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addMultipleParticipantsRb, null, "addMultipleParticipantServer" + new Date().getMilliseconds() + "_")
            return Result ?? {};
        },
        getStudentsByUserIdEmail: async function (getStudentsRb: GetStudentsByUserIdEmailRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/SbcParentAppService.svc/GetStudentsByParentId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getStudentsRb, null, "GetStudentsByParentId" + new Date().getMilliseconds() + "_")
            return Result;
        },
        getClubStatusByStudentIds: async function (getClubStatusByStudentIds: GetClubStatusByStudentIdsRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/getClubStatusByStudentIds';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getClubStatusByStudentIds, null, "GetClubStatusByStudentIdsRb" + new Date().getMilliseconds() + "_")
            return Result ?? [];
        },
        getStudentByParentEmail: async function (email: string, checkCacheFirst?: boolean) {
            if (checkCacheFirst && this.listOfStudent.length > 0) {
                return this.listOfStudent;
            } else {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/SbcParentAppService.svc/GetStudentsByParentId';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, {"parentEmail": email}, null, "GetClubStatusByStudentIdsRb" + new Date().getMilliseconds() + "_")
                return Result ?? [];
            }
        },
        getStudentByStudentId: async function (studentId: number) {

            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/StudentAttendaneServices.svc/GetStudentDetailsById';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"studentId": studentId}, null, "GetStudentByStudentId" + new Date().getMilliseconds() + "_")
            return Result ?? [];
        },
        addOrUpdateStudent: async function (studentObject: any) {
            let student = studentObject.value;
            student.Id = student.id;
            student.Grade = student.Grade.Id;
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/StudentAttendaneServices.svc/updatestudent';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {student}, null, "updateStudent" + new Date().getMilliseconds() + "_")
            return Result ?? [];
        },
        getStudentDetailsByIdServer: async function (studentId: number) {
            const config = useRuntimeConfig()
            let url = '/api/student/getStudentDetailsById';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"studentId": studentId}, null, "getStudentById" + new Date().getMilliseconds() + "_", false, true)
            return Result ?? {};
        },
        getStudentDetailsByEmail: async function (studentEmail: string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Student/GetStudentByEmail';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"Email": studentEmail}, null, "getStudentDetailsByEmail" + new Date().getMilliseconds() + "_", false, true)
            return Result ?? {};
        },
        getStudentDetailsByEmailServer: async function(studentEmail: string,companyId:any=null){
            const config = useRuntimeConfig()
            let url = '/api/student/get-student-details-by-email';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, { "Email" : studentEmail,"companyId": companyId}, null, "getStudentDetailsByEmailServer" + new Date().getMilliseconds() + "_", false, true)
            return Result ?? {};
        },
        loadAndSetLoggedInStudent: async function (studentEmail: string) {
            try {
                const response = await this.getStudentDetailsByEmail(studentEmail);
                if (response) {
                    this.loggedInStudent = response as Student;
                }
            } catch (e) {
                console.log(e)
            }
        },
        getLocalParticipantFromResponse(response: any) {
            return <LocalParticipant>{
                id: response.id,
                firstName: response.FirstName,
                lastName: response.LastName,
                email: isTemporaryEmail(response.Email) ? '' : response.Email,
                phone: (response.Phone) && (response.Phone.length > 10) ? response.Phone.substring(1) : response.Phone,
                profilePicture: response.ProfilePicture,
                gender: response.Gender,
                gradeId: response.Grade,
                allergies: response.Allergies,
            };
        },

        loadAllStudentsWithPagination: async function (body:GetStudentDataPaginationRb) {
            try {
                const ns = new NetworkService();
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Student/getStudentDataByCompanyIdAndPagination';

                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {...body}, null, "loadAllStudentsWithPagination" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        getParentsByStudentIdAndCompanyId: async function (studentId: number, companyId:number) {
            try {
                const ns = new NetworkService();
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/sbcParentAppService.svc/GetParentsByStudentIdAndCompanyId';

                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {studentId:studentId, companyId:companyId}, null, "GetParentsByStudentIdAndCompanyId" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        getParentsByStudentIdsAndCompanyId: async function (studentIds: number[], companyId:number) {
            try {
                const ns = new NetworkService();
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/sbcParentAppService.svc/GetParentsByStudentIdsAndCompanyIdV2';

                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {studentId: studentIds, companyId:companyId}, null, "GetParentsByStudentIdsAndCompanyIdV2" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        clearAllStudentOfCurrentCompany: function () {
            this.allStudentOfCurrentCompany = [];
        },
        loadAllStudents: async function (companyId: number) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Student/GetSessionStudentsByCompanyID';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {companyId:companyId}, null, "getAllStudent" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadAndSetAllStudent: async function (companyId:number) {
            try {
                let response = await this.loadAllStudents(companyId)

                if (response) {
                        this.clearAllStudentOfCurrentCompany();
                    for (const rawStudent of response ?? []) {
                        const student = rawStudent as Student;
                        this.allStudentOfCurrentCompany.push(student);

                    }
                }
                return this.allStudentOfCurrentCompany;
            } catch (e) {
                console.log(e)
            }
        },
        clearStudentsStatistics: function () {
            this.studentStatistics = <StudentStatistics>{};
        },
        clearStudentRecordsByDateRange: function () {
            this.studentsRecords = <Student[]>[];
        },
        loadStudentsStatistics: async function (companyId: number) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Student/getStudentsStatistics';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {CompanyId:companyId}, null, "loadStudentsStatistics" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadAndSetStudentsStatistics: async function (companyId:number) {
            try {
                let response = await this.loadStudentsStatistics(companyId)
                if (response) {
                    this.clearStudentsStatistics();
                    this.studentStatistics = response as StudentStatistics;
                }
                return this.studentStatistics;
            } catch (e) {
                console.log(e)
            }
        },

        loadStudentRecordsByDateRange: async function (companyId: number, From: number,To: number) {
            try {
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Student/getStudentRecordsByDateRange';
                const ns = new NetworkService();
                let {
                    Result,
                    IsOperationSuccessful
                } = await ns.post$fetch(url, {CompanyId:companyId, From:From, To:To}, null, "loadStudentRecordsByDateRange" + new Date().getMilliseconds() + "_", false)
                if (IsOperationSuccessful) {
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.ERROR,
                        title: 'Error',
                        message: Result,
                    })
                    return null;
                }
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: e,
                })
                console.log(e)
            }
        },
        loadAndSetStudentRecordsByDateRange: async function (companyId: number, From: number, To: number) {
            try {
                let response = await this.loadStudentRecordsByDateRange(companyId, From, To)
                if (response) {
                    this.clearStudentRecordsByDateRange();
                    this.studentsRecords = response as Student[];
                }
                return this.studentsRecords;
            } catch (e) {
                console.log(e)
            }
        },
    },
    getters: {
        getStudentTableSortKey: state => {
            return state.studentTableSortKey;
        },
        getAllStudentOfCurrentCompany: state => {
            return state.allStudentOfCurrentCompany;
        },
        getStudentRecordsByDateRange: state => {
            return state.studentsRecords;
        },
        getAllStudentStatisticsCurrentCompany: state => {
            return state.studentStatistics;
        },
        getActiveStudentsOfCurrentCompany: state => {
         return state.allStudentOfCurrentCompany.filter((item:Student) => item.Status == StudentStatus.ACTIVE)
        },
        getStudentIds: state => {
            return state.listOfStudent.map(student => student.id)
        },
        getStudentById: state => function (studentId: number) {
            return state.listOfStudent.find((item:any) => item.id == studentId)
        },
        getStudentFromCompanyStudentsById: state => function (studentId: number) {
            return state.allStudentOfCurrentCompany.find((item:any) => item.id == studentId)
        },
        getStudentDetailsFromAllStudentById: state => function (studentId: number) {
            return state.allStudentOfCurrentCompany.find((item) => item.id == studentId)
        },
        getLoggedInStudent: state => state.loggedInStudent,
        filterStudentsByStatus: state => function (status: number) {
            return state.listOfStudent.find((item) => item.Status == status)
        },
    },
})
